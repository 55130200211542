import OrmanView from "./orman/OrmanView";

function App() {
  return (
    <div>
      <OrmanView />
    </div>
  );
}

export default App;
